@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .thin-scrollbar::-webkit-scrollbar {
    width: 0.5rem;
  }

  .thin-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }

  .thin-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
  }

  .red-scrollbar::-webkit-scrollbar {
    width: 0.2rem;
  }

  .red-scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }

  .red-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(255, 0, 0, 0.5);
    border-radius: 0.25rem;
  }
}
